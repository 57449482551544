import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TreatmentCard from "../../components/treatment-card";
import ReviewSection from "../../components/review-section";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import TreatmentHeroVideo from "../../components/treatment-hero-video";
import { GatsbyImage } from "gatsby-plugin-image";
import PhotoLeft from "../../components/photo-left";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const InvisalignPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
			}
			schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "invisalign" }) {
				slug
				invisalignPinnerFieldGroups {
					invisalignPinneIntroSection {
						heading
						subHeading
						subtitle
						title
						description
						cta {
							target
							title
							url
						}
						videoLink {
							node {
								altText
								mediaItemUrl
							}
						}
					}
					invisalignPinneReviewSection {
						heading
						subHeading
					}
					invisalignPinneWhatYouKnowSection {
						heading
						subHeading
						items {
							heading
							content
							image {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						video {
							node {
								altText
								mediaItemUrl
							}
						}
						cta {
							title
							target
							url
						}
					}
					invisalignPinneTwoColumnSection {
						heading
						content
						cta {
							target
							title
							url
						}
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					stepsSection {
						heading
						cta {
							target
							title
							url
						}
						steps {
							content
							heading
						}
					}
					invisalignPinneFaqSection {
						heading
						faq {
							nodes {
								... on WpFaq {
									faqFields {
										question
										answer
									}
								}
							}
						}
					}
				}
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFieldGroups, invisalignPinnerFieldGroups, slug },
	} = data;

	const {
		invisalignPinneIntroSection,
		invisalignPinneReviewSection,
		invisalignPinneWhatYouKnowSection,
		invisalignPinneTwoColumnSection,
		stepsSection,
		invisalignPinneFaqSection,
	} = invisalignPinnerFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
		image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
		mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
		sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
		name: "Dental House Pinner",
		url: "https://www.dentalhousepinner.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
			image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
			name: "Dental House Pinner",
			url: "https://www.dentalhousepinner.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.dentalhousepinner.co.uk",
			datePublished: "2023-08-23",
			reviewBody:
				"As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
			author: {
				"@type": "Person",
				name: "N R",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Dental House Pinner",
				sameAs: "https://www.dentalhousepinner.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Treatments",
				item: {
					url: `${siteUrl}/treatments`,
					id: `${siteUrl}/treatments`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Invisalign",
				item: {
					url: `${siteUrl}/treatments/invisalign`,
					id: `${siteUrl}/treatments/invisalign`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/treatments/invisalign`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{invisalignPinneIntroSection &&
					!checkPropertiesForNull(invisalignPinneIntroSection, ["heading"]) && (
						<TreatmentHeroVideo
							smallHeadingTopText={invisalignPinneIntroSection?.subtitle}
							videoUrl={
								invisalignPinneIntroSection?.videoLink.node?.mediaItemUrl
							}
							heading1={invisalignPinneIntroSection?.title}
							headingSmall={invisalignPinneIntroSection?.subHeading}
							heading2={invisalignPinneIntroSection?.heading}
							text={
								<SafeHtmlParser
									htmlContent={invisalignPinneIntroSection?.description}
								/>
							}
							ctaButton={invisalignPinneIntroSection?.cta}
						/>
					)}
				<ReviewSection
					heading={
						invisalignPinneReviewSection?.heading ||
						"Reviews From Our Dentistry Patients"
					}
					smallheading={
						invisalignPinneReviewSection?.subHeading ||
						"WHAT PEOPLE SAY ABOUT US"
					}
					page="Invisalign"
				/>
				{invisalignPinneWhatYouKnowSection &&
					!checkPropertiesForNull(invisalignPinneWhatYouKnowSection, [
						"heading",
					]) && (
						<section className="pt-8 pb-md-6 pb-4">
							<Container>
								<Row>
									<Col className="text-center">
										<p className="text-accent jost-bold fs-6">
											{invisalignPinneWhatYouKnowSection?.subHeading}
										</p>
										<h2 className="text-text-secondary display-4 pb-4">
											{invisalignPinneWhatYouKnowSection?.heading}
										</h2>
									</Col>
								</Row>
								{invisalignPinneWhatYouKnowSection.items &&
									invisalignPinneWhatYouKnowSection.items.length > 0 && (
										<Row className="pt-md-8 pt-5 h-100 justify-content-center">
											{" "}
											{invisalignPinneWhatYouKnowSection.items.map(
												(cardItem) => (
													<TreatmentCard
														key={uuid4()}
														image={
															cardItem?.image.node?.localFile.childImageSharp
																.gatsbyImageData
														}
														imgAlt={cardItem?.image.node?.altText}
														title={cardItem?.heading}
														text={
															<SafeHtmlParser htmlContent={cardItem?.content} />
														}
														span={4}
														price={null}
														shorter="image-shorter"
													/>
												)
											)}
										</Row>
									)}
							</Container>
						</section>
					)}
				<section className="pb-5 pb-lg-7">
					<Container>
						{invisalignPinneWhatYouKnowSection &&
							!checkPropertiesForNull(invisalignPinneWhatYouKnowSection, [
								"video",
							]) && (
								<Row>
									<Col>
										<video style={{ width: "100%" }} controls>
											<source
												src={
													invisalignPinneWhatYouKnowSection?.video.node
														?.mediaItemUrl
												}
												type="video/mp4"
											/>
											<p>
												Video showing how the Invisalign aligner process works
											</p>
										</video>
									</Col>
								</Row>
							)}
						{invisalignPinneWhatYouKnowSection.cta &&
							invisalignPinneWhatYouKnowSection.cta.url && (
								<Row>
									<Col className="text-center mt-5 mt-md-6">
										{" "}
										<Button
											className="text-white py-3 px-5 w-100 w-md-auto"
											variant="accent"
											href={invisalignPinneWhatYouKnowSection.cta.url}
											target={
												invisalignPinneWhatYouKnowSection.cta.target || "_blank"
											}
											rel="noreferrer"
										>
											{invisalignPinneWhatYouKnowSection.cta?.title}
										</Button>
									</Col>
								</Row>
							)}
					</Container>
				</section>
				{stepsSection && !checkPropertiesForNull(stepsSection, ["heading"]) && (
					<>
						<section className="bg-primary py-5 py-lg-7 d-none d-lg-block">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className="text-white display-4 pb-5">
											{stepsSection?.heading}
										</h2>
									</Col>
								</Row>
								{stepsSection.steps && stepsSection?.steps.length > 0 && (
									<Row className="position-relative">
										{stepsSection?.steps.map((stepItem, i) => (
											<Col className="position-relative" lg={3} key={uuid4()}>
												{stepsSection?.steps.length > i + 1 && (
													<div
														style={{
															zIndex: 0,
															bottom: "30px",
															height: "5px",
														}}
														className="position-absolute w-100 bg-accent"
													></div>
												)}

												<div
													style={{
														width: "65px",
													}}
													className=" d-flex align-items-center justify-content-center "
												>
													<p className="jost-medium text-white pb-0 mb-1 fs-4">
														STEP
													</p>
												</div>
												<div
													style={{
														borderRadius: "100%",
														width: "65px",
														height: "65px",
														zIndex: 1,
													}}
													className={`bg-accent d-flex align-items-center position-relative justify-content-center `}
												>
													<h2
														className={`${
															stepsSection?.steps.length > i + 1
																? "text-primary"
																: "text-white"
														} mb-0 pb-0 d-inline-block`}
													>
														{i + 1}
													</h2>
												</div>
											</Col>
										))}
									</Row>
								)}

								<Row>
									{stepsSection?.steps.map((stepItem) => (
										<Col lg={3} key={uuid4()}>
											<h3 className="text-white fs-6 mt-5 mb-4">
												{stepItem?.heading}
											</h3>
											<div className="smaller-text">
												<SafeHtmlParser htmlContent={stepItem?.content} />
											</div>
										</Col>
									))}
								</Row>
								{stepsSection.cta && stepsSection.cta.url && (
									<Row>
										<Col className="text-center mt-5 mt-md-6">
											{" "}
											<Button
												className="text-white py-3 px-5 w-100 w-md-auto"
												variant="accent"
												href={stepsSection.cta.url}
												target={stepsSection.cta.target ?? "_blank"}
												rel="noreferrer"
											>
												{stepsSection.cta.title}
											</Button>
										</Col>
									</Row>
								)}
							</Container>
						</section>

						<section className="bg-primary py-5  d-lg-none">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className="text-white display-4 pb-5">
											{stepsSection?.heading}
										</h2>
									</Col>
								</Row>
								{stepsSection?.steps.map((stepItem, i) => (
									<Row className="position-relative" key={uuid4()}>
										<Col className="position-relative " xs={3}>
											{stepsSection?.steps.length > i + 1 && (
												<div
													style={{ zIndex: 0, left: "35px", width: "5px" }}
													className="position-absolute h-80 bottom-0 bg-accent"
												></div>
											)}
											<div
												style={{
													width: "50px",
													zIndex: 1,
												}}
												className=" d-flex align-items-center position-relative justify-content-center "
											>
												<p className="jost-medium text-white pb-0 mb-1 fs-4">
													STEPS
												</p>
											</div>
											<div
												style={{
													borderRadius: "100%",
													width: "50px",
													height: "50px",
													zIndex: 1,
												}}
												className="bg-accent d-flex align-items-center position-relative justify-content-center "
											>
												<h2
													className={`${
														stepsSection?.steps.length > i + 1
															? "text-primary"
															: "text-white"
													} mb-0 pb-0 d-inline-block`}
												>
													{i + 1}
												</h2>
											</div>
										</Col>
										<Col xs={9}>
											<h3 className="text-white fs-6  mb-3">
												{stepItem?.heading}
											</h3>
											<div className="smaller-text">
												<SafeHtmlParser htmlContent={stepItem?.content} />
											</div>
										</Col>
									</Row>
								))}
							</Container>
						</section>
					</>
				)}

				{/* <TreatmentImageSection
					img={wc1Img}
					imgAlt1={data.wc1Img?.altText}
					img2={wc2Img}
					imgAlt2={data.wc2Img?.altText}
					heading="Why choose Dental House Exeter for Invisalign?"
					text={
						<span className="text-white">
							<p className="text-white jost-medium">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi
								diam, ut nisi, suspendisse tortor, morbi gravida in. Erat lorem
								semper erat sed eget ac morbi parturient. Nisi magna cursus
								vulputate odio sed viverra.{" "}
							</p>
							<p className="text-white jost-medium">
								Lectus enim at amet diam. Morbi diam suscipit luctus amet
								dignissim. Velit donec tortor non eget curabitur at hendrerit
								nunc, nulla. Ut aliquam ut eget orci. At at varius vestibulum
								sagittis libero. Maecenas ornare nunc magna enim id convallis
								vel dis pellentesque. Ultrices nulla lacinia nulla adipiscing
								sit viverra at. Non elementum, orci, odio tortor arcu aliquam
								cursus tortor augue. Curabitur montes, id id non eget. Nulla
								semper aliquet sed elementum morbi diam est quis. Leo nec, ac
								massa eleifend molestie fringilla. Nibh eget augue et rutrum
								praesent quisque pharetra. Sed integer odio egestas viverra.
								Proin ultrices proin tellus elementum mi velit, at. Et lobortis
								eget habitasse volutpat. At dui nibh arcu pellentesque. Quis
								ultrices sed proin vitae nisl nec commodo, tincidunt in. Quam
								quis nulla in semper mollis. Felis in nibh sit vitae, semper
								augue. Neque urna, ullamcorper sed pulvinar. Lorem id lacus vel
								ullamcorper viverra massa, commodo. Ornare egestas habitasse
								duis nibh scelerisque urna malesuada aenean.
							</p>
						</span>
					}
					headingSmall="INVISALIGN EXPERTS"
				/> */}

				{invisalignPinneTwoColumnSection &&
					!checkPropertiesForNull(invisalignPinneTwoColumnSection, [
						"heading",
					]) && (
						<section>
							{" "}
							<PhotoLeft
								imgHeight="55rem"
								img={
									invisalignPinneTwoColumnSection?.image.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								imgAlt={invisalignPinneTwoColumnSection?.image.node?.altText}
								text={
									<div>
										<h2 className=" text-center text-lg-start display-4 pb-4">
											{invisalignPinneTwoColumnSection?.heading}
										</h2>
										<GatsbyImage
											className="mb-4 w-100 d-lg-none"
											image={
												invisalignPinneTwoColumnSection?.image.node?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={invisalignPinneTwoColumnSection?.image.node?.altText}
										/>
										<SafeHtmlParser
											htmlContent={invisalignPinneTwoColumnSection?.content}
										/>
										{invisalignPinneTwoColumnSection &&
											invisalignPinneTwoColumnSection.cta && (
												<Button
													className="text-white py-3 px-5 w-100 w-md-auto"
													variant="accent"
													href={invisalignPinneTwoColumnSection?.cta.url}
													target={
														invisalignPinneTwoColumnSection.cta.target ??
														"_blank"
													}
													rel="noreferrer"
												>
													{invisalignPinneTwoColumnSection?.cta.title}
												</Button>
											)}
									</div>
								}
								bgColour="bg-bg"
							/>
						</section>
					)}
				{invisalignPinneFaqSection &&
					!checkPropertiesForNull(invisalignPinneFaqSection, ["faq"]) && (
						<Faq
							heading={invisalignPinneFaqSection?.heading}
							faqs={invisalignPinneFaqSection?.faq.nodes}
						/>
					)}
			</Layout>
		</>
	);
};

export default InvisalignPage;
